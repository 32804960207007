/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* TAILWIND CSS */

@tailwind components;
@tailwind utilities;

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

/* APP GLOBAL STYLING */

html {
  padding-top: env(safe-area-inset-top);
}

p,
span,
a {
  font-family: var(--font-body), sans-serif;
  font-size: var(--text-base), 1rem;
  color: var(--primary-dark);
}

ion-label {
  font-family: var(--font-body), sans-serif;
  --ion-font-family: var(--font-body), sans-serif;
}

.m-0 {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.hundred {
  width: 100%;
}

.backorforth {
  width: 100%;
  border: 0px;
  background-color: var(--primary-strong-100);
  border-radius: 8px;
  color: var(--primary-dark);
  font-weight: bold;
  text-align: center;
  padding-block: var(--s-3);
  line-height: var(--line-height-button) !important;
  font-family: var(--font-body), sans-serif;

  ion-icon {
    font-size: var(--text-base), 1rem;
  }
  &.white {
    background-color: var(--hvit);
  }
  &.blue {
    background-color: var(--primary-strong);
  }
  &.light-blue {
    background-color: var(--primary-light);
  }
}

.ion-page {
  display: inline-block;
  border-radius: inherit;
}

.ion-margin {
  margin: 0.35em 0.7em 0.35em 0.7em;
}

.ion-padding {
  padding: 0.35em 0.2em 0.35em 0.2em;

  &.extra {
    padding: 1em 3em 1em 2em;
  }
}
.ion-padding-end {
  padding-inline-end: 0.7em;
}

.sanity-button {
  all: unset;
  background-color: transparent;
  border-radius: 0.5rem;
  border: 1px solid var(--primary-dark);
  box-sizing: border-box;
  box-shadow: 0 0;
  color: var(--primary-dark);
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  line-height: var(--line-height-button);
  padding: var(--p-button);
  position: relative;
  text-align: center;
  min-width: min(100%, var(--w-button));

  &:hover,
  &:focus {
    background-color: color-mix(
      in oklab,
      var(--primary-strong),
      transparent 75%
    );
  }
}
.sanity-button-primary {
  all: unset;
  background-color: var(--primary-dark);
  border-radius: 0.5rem;
  border: 1px solid var(--primary-dark);
  box-sizing: border-box;
  box-shadow: 0 0;
  color: var(--hvit) !important;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  line-height: var(--line-height-button);
  padding: var(--p-button);
  position: relative;
  text-align: center;
  min-width: min(100%, var(--w-button));

  &:hover,
  &:focus {
    box-shadow: inset 0 0 10em
      color-mix(in oklab, var(--primary-strong), transparent 75%);
  }
}

ion-fab-button {
  --background: var(--primary-dark);
  --color: var(--hvit);
}

ion-spinner {
  --color: var(--primary-dark);
}

.ion-col-text {
  padding-top: 7px;
}

ion-content,
ion-toolbar,
ion-header {
  border: 0px;
}

.tab-content-bottom-margin {
  margin-bottom: 60px;
}

ion-col {
  --ion-grid-column-padding: 0px;
}

.boldText {
  font-weight: bold;
  color: var(--primary-dark);
  margin-bottom: 5px;
}

.text {
  color: var(--primary-dark);
}

.bold {
  font-weight: bold;
}

.boldPadding {
  font-weight: bold;
  padding-bottom: 5px;
}

ion-title {
  text-align: center;
  border: 0;
  color: var(--primary-dark);
  font-family: var(--font-title);
  font-weight: 900;
  font-style: normal;
  font-size: var(--text-h1);
  & > h1 {
    white-space: pre-line;
  }
}

ion-title.smaller {
  & > h1 {
    font-size: var(--text-h2);
  }
}

ion-title > div:nth-of-type(1) {
  margin-top: 2vh !important;
}

ion-text {
  text-align: center;
  border: 0;
  color: var(--primary-dark);
  font-family: var(--font-title);
  font-style: normal;
}

.ion-no-border {
  margin-bottom: 46px;
}

// Sidemeny
.mimic_tab_button {
  & svg {
    margin-top: 3px;
  }
  &:hover {
    & p {
      color: var(--primary-strong) !important;
    }
    .mimic_ion_icon {
      background-color: var(--primary-strong);
      border-radius: 22.5rem;
      & svg {
        & path {
          fill: var(--hvit) !important;
        }
      }
    }
  }
}

// Hovedmeny
ion-tab-button {
  & svg {
    margin-top: 3px;
  }
  &.tab-selected svg {
    & path {
      fill: var(--hvit) !important;
    }
  }
  &.tab-selected div {
    background-color: var(--primary-strong);
    border-radius: 22.5rem;
  }
  &:hover {
    & div {
      background-color: var(--primary-strong);
      border-radius: 22.5rem;
    }
    & svg {
      & path {
        fill: var(--hvit) !important;
      }
    }
    & ion-label {
      --color: var(--primary-strong);
    }
    & ion-icon {
      color: var(--primary-strong);
    }
  }
}

//Artikkel

.article-page {
  color: var(--primary-dark);
}

/* Fakturaliste */
#fakturaliste {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  position: relative;
}

#fakturaliste > div:nth-of-type(1) {
  width: 86vw;
  max-width: 700px;
  margin: 0 auto;
  background: var(--hvit);
}

.fakturaboks {
  margin-bottom: 8px;
}

.fakt_year_header {
  margin: 15px 0;
  font-weight: 700;
  font-style: normal;
  font-size: var(--text-h2);
  color: var(--primary-dark);
}

.fakt {
  padding-top: 10px;
  padding-bottom: 3px;
  background-color: var(--primary-strong-100);
  border-radius: 8px !important;
  color: var(--primary-dark);
  font-family: var(--font-body);
  font-weight: normal;
  font-size: var(--text-sm);
  cursor: pointer;
}

.save_button {
  width: 155px !important;
  margin-top: 1rem;
}

/* Midlertidig kundeinfo på profil */
.aboutme_temp {
  padding: 15px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  line-height: var(--line-height-button);
  color: var(--primary-dark);
  font-family: var(--font-body);
  font-weight: normal;
  margin: 0 auto;
  // line-height: 135%;

  & a {
    color: var(--primary-dark);
  }

  & div.smalltext {
    font-size: var(--text-sm);
  }
}

.ingen-avtale-boks {
  border: 1px solid var(--primary-dark);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  max-width: 400px;
  margin-inline: auto;

  & p {
    margin-block: var(--s-4) !important;
  }
}

// .aboutme_temp > div:nth-child(even) {
//   //background-color: var(--primary-light-400);
//   width: 70vw;
//   max-width: 320px;
//   margin: 0 auto;
//   margin-bottom: 20px;
//   padding: 10px;
//   border-radius: 7px;
// }

// Input-felt
// ion-input {
//   --background: var(--primary-strong-100) !important;
//   --color: var(--primary-dark) !important;
//   --border-radius: 8px !important;
//   --highlight-color: var(--primary-strong-600) !important;
//   --highlight-color-valid: var(--primary-strong-600) !important;
//   --ion-color-success: var(--primary-strong-600) !important;
//   --highlight-color-focused: var(--primary-strong-600) !important;
//   font-family: var(--font-body) !important;
//   font-size: var(--text-base) !important;
// }

.form-input-ihk {
  ion-input {
    --background: initial;
    --color: var(--primary-dark);
    --highlight-color: var(--primary-dark);
    --highlight-color-valid: var(--primary-dark);
    --highlight-color-invalid: var(--danger);
    --ion-color-success: var(--primary-dark);
    --highlight-color-focused: var(--primary-dark);
    --border-radius: 0.5rem !important;
    font-family: var(--font-body) !important;
    font-size: var(--text-base) !important;
  }
  ion-textarea {
    --background: initial;
    --color: var(--primary-dark);
    --highlight-color: var(--primary-dark);
    --highlight-color-valid: var(--primary-dark);
    --highlight-color-invalid: var(--danger);
    --ion-color-success: var(--primary-dark);
    --highlight-color-focused: var(--primary-dark);
    --border-radius: 0.5rem !important;
    --border-color: var(--primary-dark) !important;
    font-family: var(--font-body) !important;
    font-size: var(--text-base) !important;
  }
  ion-radio-group {
    font-family: var(--font-body) !important;
    font-size: var(--text-base) !important;
  }
}

// Chevron på dropdown
ion-select::part(icon) {
  color: var(--primary-dark) !important;
  opacity: 1 !important;
  //padding-right: 10px !important;
}

ion-select::part(text) {
  color: var(--primary-dark);
  padding-left: 10px !important;
  font-size: var(--text-body);
}

ion-select {
  background-color: var(--hvit);
  border: 1px solid var(--primary-strong);
  color: var(--primary-dark);
  font-family: var(--font-body), sans-serif;
  font-size: var(--text-body);
  border-radius: 0.5rem;
  text-align: center;
  width: max-content;
  min-width: 250px;
  margin: auto;
  --highlight-color: var(--primary-strong) !important;
  --highlight-color-valid: var(--primary-strong) !important;
  --ion-color-success: var(--primary-strong) !important;
  --highlight-color-focused: var(--primary-strong) !important;
}
ion-select::part(icon) {
  right: 10px;
  color: var(--primary-dark) !important;
}

// Dropdown element container and items
ion-popover {
  --min-width: 300px;
  --backdrop-opacity: 0.5;
  --ion-text-color: var(--primary-dark);
}
ion-popover ion-list {
  --ion-item-background: var(--hvit);
  --color: var(--primary-dark);
}
ion-popover ion-item {
  --background: var(--hvit);
  --color: var(--primary-dark);
  --background-focused: var(--primary-light) !important;
  --background-focused-opacity: 0.4 !important;
  --background-hover: var(--primary-light) !important;
  --background-hover-opacity: 0.2 !important;
  font-size: var(--text-body);
}
ion-popover ion-item.item-radio-checked {
  --background: var(--primary-light) !important;
  --color: var(--primary-dark);
}
ion-popover ion-item.ion-activated {
  --background: var(--primary-light) !important;
  --color: var(--primary-dark);
}
ion-popover::part(backdrop) {
  background-color: var(--primary-dark);
}
ion-popover::part(content) {
  left: 0 !important;
  right: 0 !important;
  margin: auto;
}

//Legges her for å se om den endrer utseende/marginer på artikkel

h2 {
  font-family: var(--font-body), sans-serif;
  margin-top: 20px;
  font-weight: 500;
}

h3 {
  font-family: var(--font-body), sans-serif;
}

table {
  font-family: var(--font-body), sans-serif;
}

#ingress {
  font-weight: 600;
  font-size: var(--text-lead);
}

ion-menu {
  ion-header {
    margin-top: 1.5em;
    margin-bottom: 2em;
    box-shadow: none !important;
  }
}

.ihk-ion-nowrap {
  flex-wrap: nowrap !important;
}

[id^='botxo-iframeContainer-'] {
  width: 98vw !important;
}

[id^='botxo-iframe-'] {
  padding-top: 30px !important;
}

.ihk-ion-padding-top {
  padding-top: 10px;
}

// Desktop styling
@media only screen and (min-width: 768px) {
  .ihk-ion-nowrap {
    flex-wrap: wrap !important;
  }

  [id^='botxo-iframeContainer-'] {
    width: 40vw !important;
    height: 60vh !important;
    min-width: 400px !important;
    box-shadow: 5px 5px 25px 5px rgb(0 40 80 / 35%) !important;
  }

  [id^='botxo-iframe-'] {
    padding-top: 0 !important;
  }

  .ion-padding {
    padding: 0.35em 0.7em 0.35em 0.7em;

    &.extra {
      padding: 1em 3em 1em 2em;
    }
  }

  // Innholdet i taben må forskyves til høyre pga sidemenyen
  // Klassen blir autogenerert av ionic?
  .tabs-inner {
    margin-left: calc(80px + env(safe-area-inset-left));
  }

  ion-title > div:nth-of-type(1) {
    margin-top: 1vh !important;
  }
}

// Alert-meldinger
ion-alert.push-alert {
  --background: var(--hvit);
  --ion-text-color: var(--primary-dark);
  --ion-text-color-rgb: var(--primary-dark-rgb);
  --ion-color-primary: var(--primary-dark);
}

ion-alert.push-alert {
  --backdrop-opacity: 0.7;
}

ion-alert.push-alert-blocks {
  --backdrop-opacity: 1;
  --ion-backdrop-color: var(--primary);
}

h2.alert-title {
  font-size: var(--text-lead) !important;
}
div.alert-message {
  font-size: var(--text-base) !important;
  max-height: 400px !important;
}
.alert-button.ion-focused.sc-ion-alert-ios,
.alert-tappable.ion-focused.sc-ion-alert-ios {
  background-color: transparent !important;
}

pdf-viewer {
  position: static !important;
}

.air-datepicker {
  --adp-cell-background-color-selected: var(--primary-dark) !important;
  --adp-cell-background-color-selected-hover: var(
    --primary-dark-800
  ) !important;
  --adp-cell-background-color-hover: var(--primary-strong-100) !important;
  --adp-background-color-hover: var(--primary-strong-100) !important;
}

.sanity-youtube {
  margin-block: var(--s-8);
}
.sanity-image {
  margin-block: var(--s-4);
}
.sanity-highlight {
  background-color: var(--primary-light);
}
.m-0 {
  margin: 0;
}

.mb-4 {
  margin-bottom: var(--s-4);
}

.mb-8 {
  margin-bottom: var(--s-8);
}

.mt-8 {
  margin-top: var(--s-8);
}

/* Component classes */

.delete-vehicle-alert > .alert-wrapper {
  border-radius: 1rem;
}

.container-blue {
  border-radius: 0.5rem;
  padding: var(--s-2);
  background: var(--primary-light);

  @media (min-width: 576px) {
    padding: var(--s-4);
  }
}

button.alert-button.delete-button-confirm {
  background-color: var(--danger);
  border-radius: 8px;
  margin: 0;
  font-family: var(--font-body), sans-serif;
  font-weight: 700;
  font-size: var(--text-base), 1rem;
  text-transform: unset;
  line-height: var(--line-height-button) !important;
  min-width: min(100%, var(--w-button));
  height: calc(var(--line-height-button) + 2 * var(--p-button));
  padding-inline-start: var(--p-button);
  padding-inline-end: var(--p-button);
  padding-block-start: var(--p-button);
  padding-block-end: var(--p-button);
  box-shadow: none !important;
  span {
    color: var(--hvit);
  }
}
