/* Buttons */

ion-button,
:where(.button) {
  all: unset;
  margin: 0;
  font-family: var(--font-body), sans-serif;
  font-size: var(--text-base), 1rem;
  color: var(--text-button, currentcolor);
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  line-height: var(--line-height-button);
  position: relative;
  text-align: center;
  min-width: min(100%, var(--w-button));
  height: calc(var(--line-height-button) + 2 * var(--p-button));

  --background: var(--bg-button, transparent);
  --background-hover: color-mix(
    in oklab,
    var(--primary-strong),
    transparent 75%
  );
  --background-focused: color-mix(
    in oklab,
    var(--primary-strong),
    transparent 75%
  );
  --padding-start: var(--p-button);
  --padding-end: var(--p-button);
  --padding-top: var(--p-button);
  --padding-bottom: var(--p-button);
  --border-width: var(--border-width-button);
  --border-style: solid;
  --border-color: var(--border-button, var(--primary));
  --border-radius: 0.5rem;
  --shadow-button: 0 0;
  --box-shadow: var(--shadow-button);
  --hover-bg-button: color-mix(
    in oklab,
    var(--primary-strong),
    transparent 75%
  );

  &.wide {
    width: 100%;
  }
}

:where(ion-button.dark),
:where(ion-button.selected),
:where(.button-primary) {
  --bg-button: var(--primary);
  --border-button: var(--bg-button);
  --text-button: white;
}

:where(ion-button.dark-on-dark) {
  --border-button: white;
}

:where(ion-button.green),
:where(.button-success) {
  --bg-button: var(--reinlav);
  --border-button: var(--skog);
  --text-button: var(--mose);
}

.button-primary-success {
  --bg-button: var(--mose);
  --border-button: var(--mose);
  --text-button: var(--reinlav);
}
ion-button.pink,
.button-primary-pink {
  --bg-button: var(--secondary-pink-400);
  --border-button: var(--bg-button);
  --text-button: var(--primary);
}
.button-primary-pink-strong {
  --bg-button: var(--aurora, var(--secondary-pink-600));
  --border-button: var(--bg-button);
  --text-button: var(--primary);
}

/* hover specificity is higher than focus to always apply background */
ion-button:hover,
.button:hover,
.button-hover {
  --shadow-button: inset 0 0 10em var(--hover-bg-button);
}

ion-button.green:hover,
.button-success:hover,
.button-success.button-hover,
.button-primary-success:hover,
.button-primary-success.button-hover {
  --hover-bg-button: color-mix(in oklab, var(--skog), transparent 90%);
}
ion-button.pink:hover,
.button-primary-pink:hover,
.button-primary-pink.button-hover {
  --hover-bg-button: var(--primary-white);
}
ion-button.pink-strong:hover,
.button-primary-pink-strong:hover,
.button-primary-pink-strong.button-hover {
  --hover-bg-button: var(--secondary-pink-400);
}

:where(ion-button):focus,
:where(.button):focus,
:is(.button-focus, .button-focus-visible) {
  --border-button: var(--focus);
  --shadow-button: inset 0 0 0 2px var(--border-button);
}

ion-button:active,
.button-active,
.button:active {
  --shadow-button: inset 0 3px 3px rgba(0, 0, 0, 0.25);
}

/* button disabled */

ion-button.disabled,
ion-button:disabled,
.button:where([disabled], [aria-disabled='true']),
.button-disabled {
  --bg-button: var(--skodde);
  --text-button: var(--text-disabled);
  font-weight: normal;
}

ion-button:where([disabled], [aria-disabled='true']):not(:focus),
.button:where([disabled], [aria-disabled='true']):not(:focus),
.button-disabled:not(:focus) {
  --border-button: currentcolor; /* variant av --text-button */
  --shadow-button: none;
}

/* button link */

.button-link:not(:hover):not(:focus) {
  box-shadow: none;
}

.button-link:not(:focus) {
  --bg-button: transparent;
  --border-button: transparent;
}

.button-link:not(:focus) {
  --text-button: var(--link);
}

/* button summary – remove default details marker in safari */
summary.button {
  &::-webkit-details-marker,
  &::marker {
    display: none;
  }
}

/* `.button-arrow-end` layouts text and arrow icon inside `.button`. It requires an element with .button and two children: `<span>Les mer</span><svg>…</svg>`. */

.button-arrow-end {
  display: inline-grid;
  grid-template-columns: 1fr auto;
  gap: 1ch;
  align-items: center;
}

/* text-input */

:where(.text-input) {
  --bg-input: white;
  /* reset (`search` is not reset on Ios Safari with `all`) */
  appearance: textfield;
  outline: none;

  background-color: var(--bg-input);
  border: 1px solid var(--border-input-text, var(--fg));
  border-radius: 0.5rem;
  box-shadow: var(
    --shadow-input,
    inset -1px -1px 0.375rem hsl(0deg 0% 0% / 0.2)
  );
  box-sizing: border-box;
  color: var(--primary);
  font-weight: 400;
  padding: var(--p-button);
}

:where(.text-input):focus {
  --border-input-text: var(--focus);
  --shadow-input: inset 0 0 0 2px var(--border-input-text);
}

:where(.text-input)::placeholder {
  color: var(--text-disabled);
  font-style: italic;
  font-weight: 400;
}

ion-item {
  --border-width: var(--border-width-button);
  --border-style: solid;
  --border-color: var(--border-button, var(--primary));
  --border-radius: 0.5rem;
  border-radius: 8px;
  --background-focused: color-mix(
    in oklab,
    var(--primary-strong),
    transparent 75%
  );
  --background-focused-opacity: 1;
  --background-hover: color-mix(
    in oklab,
    var(--primary-strong),
    transparent 75%
  );
  --background-hover-opacity: 1;
  font-family: var(--font-body);
}

ion-checkbox {
  --border-color: var(--primary-dark) !important;
  --border-color-checked: var(--primary-dark) !important;
  --checkbox-background-checked: var(--primary-dark) !important;
  --checkbox-background: var(--hvit) !important;
  margin-right: 1rem;
}
ion-checkbox::part(label) {
  font-family: var(--font-body), sans-serif;
  --ion-font-family: var(--font-body), sans-serif;
  --color: var(--primary-dark);
  color: var(--primary-dark);
}

ion-radio-group,
ion-accordion {
  ion-item {
    border-radius: 0;
    --border-width: 0;
    --border-style: none;
    --border-color: none;
    --border-radius: 0;
  }
}
