// Tailwind CSS variables (for custom base CSS)

::before,
::after {
  --tw-content: '';
}

*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

// Ionic CSS Variables and Theming. For more info, please see
// http://ionicframework.com/docs/theming/

:root {
  --ion-color-primary: #0a66ff;
  --ion-color-primary-rgb: 10, 102, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #095ae0;
  --ion-color-primary-tint: #2375ff;

  --ion-color-secondary: #002987;
  --ion-color-secondary-rgb: 0, 41, 135;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #002477;
  --ion-color-secondary-tint: #1a3e93;

  --ion-color-tertiary: #ffd0ff;
  --ion-color-tertiary-rgb: 255, 208, 255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e0b7e0;
  --ion-color-tertiary-tint: #ffd5ff;

  --ion-color-success: #26b41b;
  --ion-color-success-rgb: 38, 180, 27;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #219e18;
  --ion-color-success-tint: #3cbc32;

  --ion-color-warning: #ff6c00;
  --ion-color-warning-rgb: 255, 108, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e05f00;
  --ion-color-warning-tint: #ff7b1a;

  --ion-color-danger: #db2600;
  --ion-color-danger-rgb: 219, 38, 0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c12100;
  --ion-color-danger-tint: #df3c1a;

  --ion-color-light: #d6f2ff;
  --ion-color-light-rgb: 214, 242, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #bcd5e0;
  --ion-color-light-tint: #daf3ff;

  --ion-color-medium: #0a66ff;
  --ion-color-medium-rgb: 10, 102, 255;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #095ae0;
  --ion-color-medium-tint: #2375ff;

  --ion-color-dark: #002987;
  --ion-color-dark-rgb: 0, 41, 135;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #002477;
  --ion-color-dark-tint: #1a3e93;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #002987;
  --ion-text-color-rgb: 0, 41, 135;

  --ion-text-color-step-50: #0d348d;
  --ion-text-color-step-100: #1a3e93;
  --ion-text-color-step-150: #264999;
  --ion-text-color-step-200: #33549f;
  --ion-text-color-step-250: #405fa5;
  --ion-text-color-step-300: #4d69ab;
  --ion-text-color-step-350: #5974b1;
  --ion-text-color-step-400: #667fb7;
  --ion-text-color-step-450: #7389bd;
  --ion-text-color-step-500: #8094c3;
  --ion-text-color-step-550: #8c9fc9;
  --ion-text-color-step-600: #99a9cf;
  --ion-text-color-step-650: #a6b4d5;
  --ion-text-color-step-700: #b3bfdb;
  --ion-text-color-step-750: #bfcae1;
  --ion-text-color-step-800: #ccd4e7;
  --ion-text-color-step-850: #d9dfed;
  --ion-text-color-step-900: #e6eaf3;
  --ion-text-color-step-950: #f2f4f9;

  --ion-background-color-step-50: #f2f4f9;
  --ion-background-color-step-100: #e6eaf3;
  --ion-background-color-step-150: #d9dfed;
  --ion-background-color-step-200: #ccd4e7;
  --ion-background-color-step-250: #bfcae1;
  --ion-background-color-step-300: #b3bfdb;
  --ion-background-color-step-350: #a6b4d5;
  --ion-background-color-step-400: #99a9cf;
  --ion-background-color-step-450: #8c9fc9;
  --ion-background-color-step-500: #8094c3;
  --ion-background-color-step-550: #7389bd;
  --ion-background-color-step-600: #667fb7;
  --ion-background-color-step-650: #5974b1;
  --ion-background-color-step-700: #4d69ab;
  --ion-background-color-step-750: #405fa5;
  --ion-background-color-step-800: #33549f;
  --ion-background-color-step-850: #264999;
  --ion-background-color-step-900: #193e93;
  --ion-background-color-step-950: #0d348d;
}

ion-title.title-large {
  --color: black;
}
