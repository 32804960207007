:root {
  /* Profile colors */
  --tundra: #002987;
  --lysning: #0a66ff;
  --glod: #ffd9c4;
  --aurora: #ffd0ff;
  --vintervarme: linear-gradient(var(--glod), var(--aurora));
  --natt: #002251;
  --isbre: #d6f2ff;
  --skodde: #f7f7f7;
  --solbar: #4d284c;
  --geitrams: #ff80ff;
  --uer: #ff6c00;
  --naust: #db2600;
  --mose: #004c39;
  --skog: #26b41b;
  --reinlav: #c8f2ac;

  /* Color aliases
   *
   * Variable names ending with a number refers to either a lighter variant or transparency.
   * Three digits, e.g. `--secondary-pink-600`, refers to a lighter variants.
   * Two digits, e.g. `--primary-light-40`, refers to a transparency.
   */

  --bg: white;
  --bg-muted: var(--skodde);
  --fg: var(--tundra);
  --fg-muted: var(--tundra);

  --primary: var(--tundra);
  --primary-800: color-mix(in oklab, var(--primary) 80%, #fff);
  --primary-dark: var(--natt);
  --primary-dark-800: color-mix(in oklab, var(--primary-dark) 80%, #fff);
  --primary-strong: var(--lysning);
  --primary-strong-800: color-mix(in oklab, var(--primary-strong) 80%, #fff);
  --primary-strong-20: var(--isbre);
  --primary-light: var(--isbre);
  --primary-light-40: color-mix(
    in oklab,
    var(--primary-light) 40%,
    transparent
  );
  --primary-light-20: color-mix(
    in oklab,
    var(--primary-light) 20%,
    transparent
  );
  --primary-light-200: var(--skodde);
  --primary-strong-100: var(--isbre);
  --primary-white: var(--skodde);
  --primary-white-98: color-mix(in oklab, var(--skodde) 98%, #000000);
  --secondary-pink: var(--geitrams);
  --secondary-pink-600: color-mix(in oklab, var(--geitrams) 60%, transparent);
  --secondary-pink-200: var(--aurora);
  --secondary-green: var(--skog);
  --secondary-green-600: var(--reinlav);
  --secondary-green-200: color-mix(in oklab, var(--reinlav) 40%, #ffffff);

  /* Util colors */
  --danger: var(--naust);
  --danger-10: color-mix(in oklab, var(--danger) 10%, transparent);
  --danger-20: color-mix(in oklab, var(--danger) 20%, transparent);
  --danger-80: color-mix(in oklab, var(--danger) 80%, #ffffff);
  --success: var(--skog);
  --success-20: color-mix(in oklab, var(--success) 20%, transparent);
  --warning: var(--uer);
  --warning-20: color-mix(in oklab, var(--warning) 20%, transparent);
  --warning-40: color-mix(in oklab, var(--warning) 40%, transparent);
  --warning-60: color-mix(in oklab, var(--warning) 60%, transparent);

  /* Util chart area colors */
  --no1: var(--uer);
  --no2: var(--skog);
  --no3: var(--geitrams);
  --no4: var(--tundra);
  --no5: var(--lysning);

  /* Util interaction colors */
  --link: inherit;
  --link-visited: var(--link);
  --focus: var(--primary-strong);
  --text-disabled: var(--solbar);
  --button-disabled-bg: #d8d8d8;
  --button-disabled-text: #5e5e61;
  /* NOTE: `--link-visited` can be redefined to activate visisted color: `.container { --link-visited: oklch(43.95% 0.121 309.21); }` */
}
