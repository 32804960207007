:root {
  --hvit: #ffffff;
  --svart: #000000;
  --graa-mork: #929ba6;
  --skygge: lightgrey;

  --error: var(--danger);
  --cancel: var(--danger);
  --chart-bg: var(--skodde);
  --text-disabled: #312d2ae6;

  // Til bruk i rgba
  --primary-dark-rgb: 0, 41, 135;
  --secondary-green-rgb: 38, 180, 27;
  --danger-rgb: 219, 38, 0;
  --warning-rgb: 255, 108, 0;
}
