/* Fonts */
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-Bold.woff2') format('woff2'),
    url('/assets/fonts/poppins/Poppins-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/poppins/Poppins-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/poppins/Poppins-BoldItalic.woff') format('woff');
  font-style: italic;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-Regular.woff2')
      format('woff2'),
    url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-Regular.woff')
      format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-Italic.woff2')
      format('woff2'),
    url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-Italic.woff')
      format('woff');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-SemiBold.woff2')
      format('woff2'),
    url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-SemiBold.woff')
      format('woff');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-SemiBoldItalic.woff2')
      format('woff2'),
    url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-SemiBoldItalic.woff')
      format('woff');
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-Bold.woff2')
      format('woff2'),
    url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-Bold.woff')
      format('woff');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-BoldItalic.woff2')
      format('woff2'),
    url('/assets/fonts/plus-jakarta-sans-2_6/PlusJakartaSans-BoldItalic.woff')
      format('woff');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}
