:root {
  /* Text */
  --text-base: 1rem;
  --text-h-hero: clamp(2 * var(--text-base), 8vw, 3 * var(--text-base));
  --text-h1: clamp(2 * var(--text-base), 8vw, 3 * var(--text-base));
  --text-h2: clamp(1.25 * var(--text-base), 6vw, 1.5 * var(--text-base));
  --text-lead: clamp(1.2 * var(--text-base), 4.5vw, 1.375 * var(--text-base));
  --text-body: clamp(1.1 * var(--text-base), 4vw, 1.25 * var(--text-base));
  --text-sm: 0.925em;
  --text-2xl: 1.5rem;
  --text-3xl: 1.875rem;
  --line-height: 1.375;
  --line-height-button: 1.375;
  --font-body: plus jakarta sans, arial, sans-serif;
  --font-title: poppins, var(--font-body);

  /* Spacing */
  --space: var(--text-body);
  --space-inline: calc(var(--space) * 1.25);
  --gap-inline: calc(var(--space) * 2);
  --s-1: calc(var(--space) * 0.25);
  --s-2: calc(var(--space) * 0.5);
  --s-3: calc(var(--space) * 0.75);
  --s-4: var(--space);
  --s-5: calc(var(--space) * 1.25);
  --s-6: calc(var(--space) * 1.5);
  --s-7: calc(var(--space) * 1.75);
  --s-8: calc(var(--space) * 2);
  --s-9: calc(var(--space) * 2.25); /* not used. remove? */
  --s-10: calc(var(--space) * 2.5); /* not used. remove? */
  --s-11: calc(var(--space) * 2.75); /* not used. remove? */
  --s-12: calc(var(--space) * 3);
  --s-13: calc(var(--space) * 3.25);
  --s-16: calc(var(--space) * 4);
  /* viewport x-axis edge to content space */
  --space-edge-x: 4.125vw;

  /* Widths */
  --w-full: calc(100vw - var(--space-edge-x) * 2);
  --w-prose: calc(45 * var(--text-body));
  --w-prose-edge-x: calc(var(--w-prose) + 2 * var(--space-edge-x));
  /* `--w-col`, width for column, but prefer  `grid` or `flex` with `gap-inline: var(--gap-inline)` */
  --w-col: calc(
    (var(--w-full) - var(--gap-inline) * (var(--grid-cols-base) - 1)) /
      var(--grid-cols-base)
  );
  --w-cover-x: var(--w-col);

  /* Other utilities */
  --outline: 3px solid var(--focus);

  /* Button */
  --w-button: var(--w-col);
  --border-width-button: 1px;
  --p-button: calc(var(--s-3) - var(--border-width-button));

}

/* Headings */

h1,
:where(.heading-1),
h2,
:where(.heading-2),
h3,
:where(.heading-3) {
  line-height: 1.175;
  color: var(--primary-dark);
}

h1,
:where(.heading-1) {
  font-family: var(--font-title);
  font-size: var(--text-h1);
  font-weight: bold;
}

h2,
:where(.heading-2) {
  font-size: var(--text-h2);
  font-weight: 600;
}

h3,
:where(.heading-3) {
  font-size: var(--text-lead);
  font-weight: 600;
}

:where(h4, h5, h6) {
  font-size: 1em;
  font-weight: 600;
}
