//////////////////
// Modal

ion-modal::part(content) {
  text-align: center;
  box-shadow: none !important;
  border-radius: 18px;
  height: 80vh;
  width: 94vw;
}

ion-modal .ion-page:not(ion-nav .ion-page) {
  position: absolute !important;
}

.modalClose {
  z-index: 1000;
  overflow: visible;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.modalClose img {
  width: 26px;
  border-radius: 50%;
}

ion-modal::part(backdrop) {
  --background: rgba(155, 155, 155, 0.2) !important;
}

@media only screen and (max-width: 575px) {
  ion-modal.logout-modal-content {
    &::part(content) {
      height: 400px;
      width: 400px;
      max-width: 400px;
      max-height: 400px;
    }
  }
}

// Narrow web / tablet modal
@media only screen and (min-width: 576px) {
  ion-modal::part(content) {
    height: 70vh;
    width: 70vw;
  }
}

// Desktop / tablet modal
@media only screen and (min-width: 768px) {
  ion-modal::part(content) {
    min-width: min(80vw, 500px);
    max-width: 1000px;
    width: 50%;
    min-height: min(90vh, 600px);
    max-height: 95vh;
    height: 50%;
  }

  // Spesifikk modalstyling
  ion-modal.download-forbruk-modal-content {
    &::part(content) {
      height: 55%;
      width: 450px;
      min-width: min(80vw, 400px);
      max-height: 650px;
    }
  }
  ion-modal.show-regning-modal-content {
    &::part(content) {
      height: 85%;
      max-height: 780px;
      max-width: 500px;
    }
  }
  ion-modal.show-faktura-modal-content {
    &::part(content) {
      height: 100%;
      max-height: 90vh;
      width: 80vw;
      max-width: 1200px;
    }
  }
  ion-modal.endre-fakturering-modal-content {
    &::part(content) {
      height: 50%;
      max-height: 610px;
      width: 70%;
      max-width: 600px;
    }
  }
  ion-modal.betalingsutsettelse-modal-content {
    &::part(content) {
      height: 780px;
      width: 500px;
    }
  }
  ion-modal.sammenligne-avtaler-modal-content {
    &::part(content) {
      min-height: min(90vh, 710px);
      width: 90vw;
    }
  }
  ion-modal.endre-passord-modal-content {
    &::part(content) {
      height: 550px;
      width: 500px;
    }
  }
  ion-modal.endre-kontaktinfo-modal-content {
    &::part(content) {
      height: 550px;
      width: 600px;
    }
  }
  ion-modal.endre-kundetilganger-modal-content {
    &::part(content) {
      height: 650px;
      width: 550px;
    }
  }
  ion-modal.confirm-change-modal-content {
    &::part(content) {
      height: 550px;
      width: 500px;
    }
  }
  ion-modal.info-msg-modal-content {
    &::part(content) {
      height: 500px;
      min-height: 450px;
      width: 500px;
    }
  }
  ion-modal.spotprices-modal-content {
    &::part(content) {
      height: 90vh;
      width: 500px;
    }
  }
  ion-modal.rabattkode-modal-content {
    &::part(content) {
      height: 400px;
      min-height: 400px;
      width: 400px;
    }
  }
  ion-modal.slette-bruker-modal-content {
    &::part(content) {
      height: 550px;
      width: 400px;
    }
  }
  ion-modal.add-location-modal-content {
    &::part(content) {
      height: 100%;
      max-height: 700px;
      min-width: 550px;
    }
  }
  ion-modal.show-charging-location-modal-content {
    &::part(content) {
      max-height: 700px;
      min-width: 550px;
    }
  }
  ion-modal.unlink-vendor-modal-content {
    &::part(content) {
      height: 100%;
      max-height: 700px;
      min-width: 550px;
    }
  }
  ion-modal.logout-modal-content {
    &::part(content) {
      height: 400px;
      width: 400px;
      max-width: 400px;
      max-height: 400px;
    }
  }
  ion-modal.feedback-modal-content {
    &::part(content) {
      height: 550px;
      width: 500px;
    }
  }
}

.info-message-ion-content {
  & div {
    text-align: center;
    margin: 30px 50px;
    color: var(--primary-dark) !important;
  }

  & h2 {
    font-family: var(--font-title);
    // font-size: 2em;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  & h3 {
    font-weight: 700;
    // font-size: 1.3em;
    margin: 0;
    margin-bottom: 20px;
    white-space: pre-line;
  }
  & p {
    font-family: var(--font-body);
    // font-size: 1.2em;
    margin: 0;
    white-space: pre-line;
  }
}
